<template>
  <NotLogged
    :welcome="welcome"
    formTitle="Reset hasła"
    :afterMessage="message"
    :afterMessageType="alertVariant"
  >
    <template #form>
      <form @submit.prevent="onSubmit">
        <input type="hidden" name="token" v-model="form.token">
        <input
          id="input-1"
          name="email"
          v-model="form.email"
          placeholder="email"
          type="email"
          :disabled="isBusy"
          readonly
          required
        >
        <input
          id="input-2"
          @focus="message = ''"
          ref="password"
          name="password"
          v-model="form.password"
          placeholder="nowe hasło"
          type="password"
          :class="{'border-red-500': message.length > 0 }"
          :disabled="isBusy"
          required
        >
        <input
          id="input-3"
          @focus="message = ''"
          ref="password_confirmation"
          v-model="form.password_confirmation"
          placeholder="Powtórz nowe hasło"
          type="password"
          :class="{'border-red-500': message.length > 0 }"
          :disabled="isBusy"
          required
        >
        <div><b>Hasło powinno zawierać:</b>
          <ol class="text-sm list-decimal list-inside text leading-4">
            <li>minimum 8 znaków</li>
            <li>co najmniej jedną wielką literę</li>
            <li>co najmniej jedną małą literę</li>
            <li>co najmniej jedną cyfrę lub znak specjalny (! @ # $ % ^ & * itp)</li>
          </ol>
        </div>

        <button type="submit" class="btn btn-primary w-full rounded-sm mt-2" :disabled="isBusy">
          <span v-if="!isBusy">Resetuj hasło</span>
          <span v-else>Zapisuję...</span>
        </button>
        <div class="block text-right mb-4 mt-1">
          <router-link :to="{ name: 'login' }">Powrót do strony logowania</router-link>
        </div>
      </form>
    </template>
  </NotLogged>
</template>

<script>
import axios from 'axios';
import NotLogged from '../components/NotLogged.vue';

export default {
  name: 'PasswordReset',
  components: {
    NotLogged,
  },
  data() {
    return {
      form: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      message: '',
      errorMessages: [],
      isBusy: false,
      welcome: {
        content: `Twoje hasło przetrzymujemy w bazie danych w formie zaszyfrowanej, więc nie możemy Ci go przypomnieć.
            Wpisz w pole obok nowe hasło do aplikacji oraz powtórz je w kolejnym polu.`,
        footer: 'Zespół Estimates Likwidacja Szkód',
      },
    };
  },
  mounted() {
    this.form.token = this.$route.params.token;
    this.form.email = this.$route.query.email;
    this.$refs.password.focus();
  },
  methods: {
    onSubmit() {
      this.isBusy = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/password/reset`, this.form)
        .then(response => {
          this.alertVariant = 'success';
          this.message = response.data.message;
          this.isBusy = false;
        })
        .catch(error => {
          if (error.response !== undefined) {
            this.alertVariant = 'warning';
            this.errorMessages = error.response.data.errorMessages;
            this.message = error.response.data.message;
            if (typeof errorMessages === 'object') {
              const messagesStr = this.errorMessages.join(', ');
              this.message = `${this.message}: ${messagesStr}`;
            }
          } else {
            this.alertVariant = 'danger';
            this.message = 'Wystapił nieznany błąd, być może serwer jest zajęty, spróbuj ponownie później';
          }
          this.isBusy = false;
        });
    },
  },
};
</script>
